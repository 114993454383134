import { render, staticRenderFns } from "./CartLine.vue?vue&type=template&id=796983b6&"
import script from "./CartLine.vue?vue&type=script&lang=ts&"
export * from "./CartLine.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductDiscountLabels: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/14/a/NuxtApp/components/product/DiscountLabels.vue').default,BaseInputNumber: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/14/a/NuxtApp/components/base/InputNumber.vue').default,BaseButton: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/14/a/NuxtApp/components/base/Button.vue').default})
